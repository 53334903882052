import React from 'react';
import styles from './Box.module.scss'
import Image from 'next/image';

const Box = (props: any) => {
  return (
    <div className={styles['box-container']}>
       <Image src={props.path} width={props.width} height={props.height}   alt={props.alt} />
      {/* <img src={props.path} className={styles['box-img']} alt='name' width={props.width} height={props.height}/> */}
      {/* <p>{props.name}</p> */}
    </div>
  );
};

export default Box;
