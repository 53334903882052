import React, { useState } from "react";
import Card from "../../Common/Card/Card";
import styles from "./Offer.module.scss";
import Image from "next/image";

const Offer = () => {
  const [image, setImage] = useState("headless-commerce.svg");

  return (
    <div className={styles["offer-container"]}>
      <div className={styles["heading-box"]}>
        <div className={styles["offer-heading"]}>
          <h1 className={styles["what"]}>What&#160;</h1>
          <h1 className={styles["we-offer"]}>we offer...</h1>
        </div>
        <p className={styles["text"]}>And there’s so much more</p>
      </div>
      <Image
        width={378}
        height={320}
        src="/rectangle.webp"
        alt="rectangle"
        className={styles["rectangle"]}
      />

      <div className={styles["img-cards-container"]}>
        <div className={styles["cards"]}>
          <div
            onMouseOver={() => setImage("headless-commerce.svg")}
            className={styles["card"]}
          >
            <Card
              heading="Headless commerce"
              text="By utilising headless commerce, we enable the teams to build whatever they want and however they want it"
            />

            <Image
              src="headless-commerce.svg"
              alt="Headless Commerce"
              className={styles["card-image"]}
              width={300}
              height={270}
            />
          </div>

          <div
            onMouseOver={() => setImage("our-vision.svg")}
            className={styles["card"]}
          >
            <Card
              heading="Our vision"
              text="Supporting more startups and scaling in other sectors while building our own product journey."
            />
            <Image
              src="our-vision.svg"
              alt="Our Vision"
              width={300}
              height={270}
              className={styles["card-image"]}
            />
          </div>

          <div
            onMouseOver={() => setImage("plugin-and-vesting.svg")}
            className={styles["card"]}
          >
            <Card
              heading="Plugin and vesting"
              text="We act as their crucial stakeholders providing technology and team support."
            />
            <Image
              src="plugin-and-vesting.svg"
              alt="background-img"
              width={300}
              height={270}
              className={styles["card-image"]}
            />
          </div>
        </div>
        <div>
          <Image
            width={530}
            height={470}
            src={image}
            className={styles["img"]}
            alt="image"
          />
        </div>
      </div>
    </div>
  );
};

export default Offer;
