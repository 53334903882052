import React, { useState } from 'react';
import styles from './Connectors.module.scss';
import Image from 'next/image';

const Connectors = () => {

    const [activeTab, setActiveTab] = useState('Technology')

    const tabs = ['Technology', 'Data Source', 'Cloud', 'Gateway', 'Shipment']

    return (
        <div className={styles['connectors-wrapper']}>
            <div className={styles['connectors']}>
                <div className={styles['connectors-head']}>
                    <h3>Omnichannel selling</h3>

                    <div className={styles['omni-info']}>
                        <span className={styles['text-right']}>Inbound marketing</span>
                        <span className={styles['round-up']}></span>
                        <span>Outbound marketing</span>
                    </div>

                    <div className={styles['omni-info']}>
                        <span className={styles['text-right']}>Over-selling</span>
                        <span className={styles['round-up']}></span>
                        <span>Cross-selling</span>
                    </div>
                </div>

                <div className={styles['icons']}>
                    <div className={styles['icon']}>
                        <Image src="/omnichannels/google-logo.svg" alt="Google" height={50} width={50} />
                    </div>
                    <div className={styles['icon']}>
                        <Image src="/omnichannels/instagram.svg" alt="Instagram" height={50} width={50} />
                    </div>
                    <div className={styles['icon']}>
                        <Image src="/omnichannels/linkedin-logo.svg" alt="Linkedin" height={50} width={50} />
                    </div>
                    <div className={styles['icon']}>
                        <Image src="/omnichannels/messenger-logo.svg" alt="Messenger" height={50} width={50} />
                    </div>

                    <div className={styles['icon']}>
                        <Image src="/omnichannels/snapchat-logo.svg" alt="Snapchat" height={50} width={50} />
                    </div>

                    <div className={styles['icon']}>
                        <Image src="/omnichannels/twitter-logo.svg" alt="Twitter" height={50} width={50} />
                    </div>

                    <div className={styles['icon']}>
                        <Image src="/omnichannels/whatsapp-logo.svg" alt="Whatsapp" height={50} width={50} />
                    </div>

                    <div className={styles['icon']}>
                        <Image src="/omnichannels/youtube-logo.svg" alt="Youtube" height={50} width={50} />
                    </div>

                    <div className={styles['socommerz-btn']}>
                        <span>SOCOMMERZ</span>
                    </div>
                </div>

            </div>

            <div className={`${styles['connectors']} ${styles['connectors-light']}`}>
                <div className={styles['connectors-head']}>
                    <h3>Connectors</h3>
                    {/* <div className={styles['tabs']}>
                        {tabs.map((item: any, index: any) => (
                            <div key={`tabs-${index}`} className={activeTab == item ? `${styles['active-tab']} ${styles['tab']}` : styles['tab']} onMouseEnter={() => setActiveTab(item)}>
                                <span>{item}</span>
                            </div>
                        ))}
                    </div> */}
                </div>

                <div className={styles['big-icons']}>

                    <div className={styles['openai-btn']}>
                        <Image src="/omnichannels/openai.svg" alt="openai" height={20} width={20} />
                        <span>OpenAI</span>
                        <span className={styles['new']}>New</span>
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/clevertap.png" alt="Clevertap" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/firebase.png" alt="Firebase" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/google-business.png" alt="Google Business" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/hotjar.png" alt="Hotjar" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/mailchimp.png" alt="Mail Chip" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/mixpanel.png" alt="Mixpanel" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/mongodb.png" alt="Mongodb" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} `}>
                        <Image src="/omnichannels/mysql.png" alt="Mysql" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/dhl.webp" alt="DHL" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/delhivery.webp" alt="delhivery" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/fedex.webp" alt="fedex" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/shippit.webp" alt="shippit" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/shiprocket.webp" alt="shiprocket" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/e-wallets.webp" alt="E Wallet" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/cashfree.webp" alt="Chasfree" height={50} width={50} />
                    </div>

                    <div className={`${styles['big-icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/paypal.webp" alt="Paypal" height={50} width={50} />
                    </div>


                    <div className={styles['med-icons']}>
                        <div className={`${styles['med-icon']} `}>
                            <Image src="/omnichannels/pos.png" alt="POS" height={50} width={50} />
                        </div>
                        <div className={`${styles['med-icon']} `}>
                            <Image src="/omnichannels/twilio.png" alt="Mysql" height={50} width={50} />
                        </div>
                        <div className={`${styles['med-icon']} `}>
                            <Image src="/omnichannels/whatsapp-business.png" alt="Mysql" height={50} width={50} />
                        </div>
                        <div className={`${styles['med-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/razorpay.webp" alt="Razorpay" height={50} width={50} />
                        </div>

                        <div className={`${styles['med-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/stripe.webp" alt="Stripe" height={50} width={50} />
                        </div>

                        <div className={`${styles['med-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/aws.webp" alt="AWS" height={50} width={50} />
                        </div>

                        <div className={`${styles['med-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/azure.webp" alt="Azure" height={50} width={50} />
                        </div>

                        <div className={`${styles['med-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/digital-ocean.webp" alt="Digital Ocean" height={50} width={50} />
                        </div>
                        {/* <div className={`${styles['icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/gcp.webp" alt="GCP" height={50} width={50} />
                    </div>

                    <div className={`${styles['icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/docker.webp" alt="Docker" height={50} width={50} />
                    </div>

                    <div className={`${styles['icon']} ${styles['icon-border']}`}>
                        <Image src="/omnichannels/kubernetes.webp" alt="Kubernetes" height={50} width={50} />
                    </div> */}
                    </div>

                    <div className={styles['sub-icons']}>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/aws.webp" alt="AWS" height={50} width={50} />
                        </div>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/oracle.webp" alt="Oracle" height={50} width={50} />
                        </div>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/postgres.webp" alt="Postgres" height={50} width={50} />
                        </div>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/snowflake.webp" alt="Snowflake" height={50} width={50} />
                        </div>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/gcp.webp" alt="GCP" height={50} width={50} />
                        </div>

                        <div className={`${styles['sub-icon']} ${styles['icon-border']}`}>
                            <Image src="/omnichannels/docker.webp" alt="Docker" height={50} width={50} />
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}

export default Connectors