import React from "react";
import Box from "../../Common/Box/Box";
import styles from "./Association.module.scss";
import Marquee from "react-fast-marquee";
import Button from "../../Common/Button/Button";

const Association = () => {
  return (
    <div className={styles["container"]}>
      <img
        src="group151.webp"
        alt="background-img"
        className={styles["bg-mobile"]}
      />
      <div className={styles["heading-box"]}>
        <div className={styles["heading"]}>
          <span className={styles["our"]}>
            Our&nbsp;
            <span className={styles["associations"]}>associations</span>
          </span>
        </div>
        <div className={styles["text-div"]}>
          <p className={styles["text-1"]}>
            We help startups and Enterprises unify their planning, from demand
            and merchandise to supply chain and operations, for maximum customer
            satisfaction with our incubation program.
          </p>
        </div>
      </div>
      <Marquee style={{ zIndex: -1 }} speed={100}>
        <div className={styles["box-1"]}>
          <Box
            path="/companyLogo/happy-nature.webp"
            width={69}
            height={70}
            alt={"Happy Nature"}
          />
          <Box
            path="/companyLogo/cybersettle.webp"
            width={180}
            height={38}
            alt={"Cyber Settle"}
          />
          <Box
            path="/companyLogo/symphony-ai.webp"
            width={208}
            height={28}
            alt={"Symphony Ai"}
          />
          <Box
            path="/companyLogo/zalon-logo.svg"
            width={280}
            height={72}
            alt={"Zalon"}
          />
          <Box
            path="/companyLogo/lal-10.webp"
            width={280}
            height={72}
            alt={"Lal 10"}
          />
          <Box
            path="/companyLogo/blinktrust.webp"
            width={280}
            height={72}
            alt={"Blinktrust"}
          />

          <Box
            path="/companyLogo/native.webp"
            width={280}
            height={72}
            alt={"Native"}
          />
          <Box
            path="/companyLogo/two-brothers.webp"
            width={280}
            height={72}
            alt={"Two Brothers"}
          />
          <Box
            path="/companyLogo/wishbox.webp"
            width={280}
            height={72}
            alt={"Wishbox"}
          />

          {/* <Box path="/companyLogo/btc.webp" width={280} height={72} alt="BTC" />
          <Box path="/companyLogo/ed-crawler.webp" width={280} height={72} alt="Ed Crawler" /> */}
          {/* <Box path="/companyLogo/hey-homie.webp" width={280} height={72} alt="Hey Homie" /> */}
          <Box
            path="/companyLogo/lawyered.webp"
            width={280}
            height={72}
            alt="Lawyered"
          />
          <Box
            path="/companyLogo/matrix-media.webp"
            width={280}
            height={72}
            alt="Matrix"
          />
          <Box
            path="/companyLogo/profluence.webp"
            width={280}
            height={72}
            alt="Profluence"
          />
          <Box path="/logos/spndx.png" width={280} height={72} alt="Spendngx" />
        </div>
      </Marquee>

      <Marquee style={{ zIndex: -1 }} speed={100} direction="right">
        <div className={styles["box-1"]}>
          <Box
            path="/companyLogo/spencers.webp"
            width={280}
            height={72}
            alt="Spencer"
          />
          <Box
            path="/companyLogo/jumppers.webp"
            width={280}
            height={72}
            alt="Jsl"
          />
          <Box
            path="/companyLogo/meil.webp"
            width={280}
            height={72}
            alt="Meil"
          />
          <Box
            path="/companyLogo/essar.webp"
            width={280}
            height={72}
            alt="Essar"
          />
          <Box
            path="/companyLogo/more.webp"
            width={280}
            height={72}
            alt="More"
          />
        </div>
      </Marquee>
    </div>
  );
};

export default Association;
