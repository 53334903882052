import React from 'react'
import styles from './FeatureList.module.scss'
import Image from 'next/image'

const FeatureList = () => {
  const featureData = {
    tag: 'Build your Business',
    title: 'Expedite your product deliverables',
    subTitle:
      'From Domain expertise to building the best technical teams for your Business.',
    features: [
      // {
        //   icon: '/product-icon10.png',
        //   title: 'Retail and E-commerce Experts',
        //   description:
        //     'We build a technology team to support your vision statement along with providing you with a fractional CTO at discounted development cost.',
        // },
        // {
        //   // icon: '/icons/command.svg',
        //   icon: '/product-icon10.png',
        //   title: 'Generative AI & LLM',
        //   description:
        //     'We excel in  Prompt Engineering and building GenAI Applications, sitting over your complex data and to give you a smarter way to visualize, analyse and make business decisions. ',
        // },
        // {
        //   // icon: '/icons/message-smile.svg',
        //   icon: '/features-icon.webp',
        //   title: 'Short-to-Mid Term Project',
        //   description:
        //     'Migrate from your old tech. stack to the new age technologies for better scalability and robustness following the latest coding practices and methods. ',
        // },
      {
        // icon: '/icons/message-chat.svg',
        icon: '/features-icon.webp',
        title: 'A Strong technology team',
        description:
          'We build a technology team to support your vision statement along with providing you with a fractional CTO at discounted development cost.',
        },

      {
        // icon: '/icons/chart.svg'
        icon: '/product-icon5.png',
        title: 'Open AI wrappers',
        description:
          'We have successfully delivered 50+ wrappers using OpenAI to support different product lines for Retail, B2B and creative Business. ',
      },
      {
        // icon: '/icons/message-heart.svg',
        icon: '/product-icon5.png',
        title: 'Business Analysis',
        description:
          'We discover, study and document business needs in collaboration with stakeholders and build foolproof solutions that fit like a glove.',
      },
      {
        icon: '/features-icon.webp',
        title: 'Build Retail modules',
        description:
          'Stay at the forfront of innovation with OpenAI. As teh technology evolves, so should the applications. We build wrappers to ensure your business remains ahead of the curve with the power of AI.',
      },
      {
        icon: '/product-icon10.png',
        title: 'OpenAI as a service',
        description:
          'As a trailblazer in the industry, Socommerz is committed to deliver unparalleled solutions driven by the transformative capabilities of OpenAI to hook with your Business Plans.',
      },
      {
        icon: '/product-icon5.png',
        title: 'Extended retail capability',
        description:
          'Extend your business across multiple channels to Sell, Analyse and take business decisions enabled by our Retail tools and utilities. ',
      },
      {
        icon: '/features-icon.webp',
        title: 'Augment Engineering Team',
        description:
          'Socommerz, have been successful to digitally transform traditional business, with our Engineering team to cognitively work with your leadership. ',
      },
      {
        icon: '/product-icon10.png',
        title: 'Build.Operate.Transfer',
        description:
          'We help to build a robust applications for you to allow smooth operations for your business with a transferable and controlled Codebase. ',
      },
      {
        icon: '/product-icon5.png',
        title: 'Data architecture-As-A service',
        description:
          "Solve a problem or Migrating a legacy application to help scale the Business. Socommerz and it's leadership help you to transform the applications to the latest tech. stacks.",
      }
    ],
  }

  return (
    <div className={styles['feature-list-wrapper']}>
      <div className={'tag'}>
        <span>{featureData.tag}</span>
      </div>
      <h2 className={styles['feature-title']}>{featureData.title}</h2>
      <div className={styles['feature-sub-title']}>{featureData.subTitle}</div>
      <div className={styles['feature-list']}>
        {featureData.features.map((item: any, index: any) => (
          <div className={styles['feature-card']} key={`feature-card-${index}`}>
            <div className={styles['feature-icon']}>
              <Image
                height={48}
                width={48}
                alt={`feature-icon-${index}`}
                src={item.icon}
              />
            </div>
            <div className={styles['feature-card-title']}>{item?.title}</div>
            <div className={styles['feature-card-sub-title']}>
              {item?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeatureList
