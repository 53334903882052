import React from 'react'
import styles from './GenerateSales.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import Button from '../../Common/Button/Button'

const GenerateSales = () => {
  const featureData = {
    tag: 'Build your Business',
    title: 'Expedite your product deliverables',
    subTitle:
      'From Domain expertise to building the best technical teams for your Business.',
    features: [
      {
        icon: '/features-icon.webp',
        title: 'Build Retail modules',
        description:
          'Stay at the forfront of innovation with OpenAI. As teh technology evolves, so should the applications. We build wrappers to ensure your business remains ahead of the curve with the power of AI.',
      },
      {
        icon: '/product-icon10.png',
        title: 'OpenAI as a service',
        description:
          'As a trailblazer in the industry, Socommerz is committed to deliver unparalleled solutions driven by the transformative capabilities of OpenAI to hook with your Bussiness Plans.',
      },
      {
        icon: '/product-icon5.png',
        title: 'Extended retail capability',
        description:
          'Extend your business across multiple channels to Sell, Analyse and take business decisions enabled by our Retail tools and utilities. ',
      },
      {
        icon: '/features-icon.webp',
        title: 'Augment Engineering Team',
        description:
          'Socommerz, have been successful to digitally transform traditional business, with our Engineering team to cognitively work with your leadership. ',
      },
      {
        icon: '/product-icon10.png',
        title: 'Build.Operate.Transfer',
        description:
          'We help to build a robust applications for you to allow smooth operations for your business with a transferable and controlled Codebase. ',
      },
      {
        icon: '/product-icon5.png',
        title: 'Data architecture-As-A service',
        description:
          "Solve a problem or Migrating a legacy application to help scale the Business. Socommerz and it's leadership help you to transform the applications to the latest tech. stacks.",
      },
    ],
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-wrapper']}>
        {false && (
          <div className={styles['header']}>
            <div className={styles['left-header-content']}>
              <div className={'tag'}>
                <span>
                  <b>New!</b> Open AI modals
                </span>
              </div>
              <h1>Generate Sales</h1>
              <p>With our Open AI modals for Omni-channel Retail solutions.</p>
            </div>

            <div className={styles['left-header-content']}>
              <Image
                width={550}
                height={498}
                src='/sales-mobile.png'
                alt='mobile-app'
              />
            </div>
          </div>
        )}

        {false && (
          <div className={styles['section-one']}>
            <div className={styles['header-wrapper']}>
              <div className={styles['section-one-header']}>
                <h2>
                  Trusted more than 30+ Enterprises, one of the largest CPG, and
                  Multiple Retail Business.
                </h2>
                <p>
                  Our retail product line is unmatched in cross platform
                  functionalities, speed and <br /> scaleability.
                </p>
              </div>
            </div>

            <div className={styles['section-one-content-wraper']}>
              <div className={styles['heading']}>
                <div className='tag'>
                  <span>Features</span>
                </div>
                <h2>Unlock Smart Retail extensions</h2>
                <p>
                  We help you with creating Modules, Applications, Wrappers,{' '}
                  <span> Open AI modals </span> to <br /> give a meaningful
                  shape to your enterprise.
                </p>
              </div>

              <div className={styles['feature-list-wrapper']}>
                <div className={styles['feature-list']}>
                  {featureData.features.map((item: any, index: any) => (
                    <div
                      className={styles['feature-card']}
                      key={`feature-card-${index}`}
                    >
                      <div className={styles['feature-icon']}>
                        <Image
                          height={48}
                          width={48}
                          alt={`feature-icon-${index}`}
                          src={item.icon}
                        />
                      </div>
                      <div className={styles['feature-card-title']}>
                        {item?.title}
                      </div>
                      <div className={styles['feature-card-sub-title']}>
                        {item?.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {false && (
          <div className={styles['section-two']}>
            <div className={styles['section-two-wrapper']}>
              <div className={styles['section-two-header']}>
                <h2>Retail Stack</h2>
                <p>Everything you need to for Retail</p>
              </div>

              <div className={styles['section-two-content']}>
                <p>
                  Build your own suite using our plug and play suite to help
                  your retail journey
                </p>

                <div className={styles['list']}>
                  <h3>Retail API suite</h3>
                  <ul>
                    <li>Price Comparision Tools</li>
                    <li>Assortment Intelligence</li>
                    <li>Pricing and Placement Optimisers</li>
                    <li>Monitoring Agents</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles['generavit-ai']}>
          <div className={styles['generavit-ai-header-right']}>
            <Image
              src={'/generative-ai.webp'}
              alt=''
              width={500}
              height={500}
              className={styles['technology-image']}
            ></Image>
          </div>

          <div className={styles['generavit-ai-header-left']}>
            <h1>Build faster and innovate with generative AI services</h1>
            <p>
              Unlock the power of AI with LLMs. Get comprehensive generative AI
              solutions for building custom, secure applications for your unique
              business needs.
            </p>
            <br />
            <br />
            <div className={styles['button-container']}>
              <Link href={'/generative-ai'}>
                <Button text='Know More' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateSales
