import React from 'react'
import styles from './Stats.module.scss'

const Stats = ({ data }: any) => {
  return (
    <div className={styles['stats-container']}>
      <div className={styles['stats-head']}>
        <h1>{data.title}</h1>
        <p>{data.subTitle}</p>
      </div>
      <div className={styles['stats']}>
        {data.numbers.map((item: any, index: any) => (
          <div
            className={styles['stats-card']}
            key={`stats-${item.title}-${index}`}
          >
            <span>{item.count}</span>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stats
