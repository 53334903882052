import React from 'react';
import styles from './Card.module.scss';

const Card = (props: any) => {
  return (
    <div className={styles['card-container']}>
      {/* <img src={props.path} /> */}
      {/* <div className={styles['card']}> */}
        <div >
          <p className={styles['rectangle']}></p>
        </div>
        <div>
          <h1 className={styles['card-heading']}>{props.heading}</h1>
          <p className={styles['card-text']}>{props.text}</p>
        </div>
      {/* </div> */}
    </div>
  );
};

export default Card;
