import React from "react";
import styles from "./AiLanding.module.scss";
import Button from "../../Common/Button/Button";
import homeStyles from "../../../styles/Home.module.scss";
function AiLanding() {
  const data: Array<string> = [
    "Up-to-Date Insights",
    "Cleansed and Validated",
    "Harmonised and Normalised",
    "Comprehensive Coverage",
    "Actionable Insights",
    "Multiple Data Source",
  ];

  return (
    <div className={styles["ai-container-landing"]}>
      <div className={styles["ai-container-header"]}>
        <h1>
          Discover data set through our{" "}
          <span className={homeStyles["span-gradient"]}>Data Lake</span>
        </h1>
      </div>
      <div className={styles["ai-container-subHeader"]}>
        <p>
          All the tools you need to effortlessly discover, collect, and curate
          web data at scale.
        </p>
        <p>
          From initial training to fine-tuning your models—we’ve got you
          covered.
        </p>
      </div>
      <div className={styles["ai-container-feature"]}>
        {data?.map((item: string, index: number) => (
          <li key={`feature-${index}`} className={styles["list-item"]}>
            <img
              src="features-icon2.webp"
              alt="icon1"
              style={{ width: 23.33, height: 23.33, marginTop: 10 }}
            />
            <p className={styles["list-item-text"]}>{item}</p>
          </li>
        ))}
      </div>
    </div>
  );
}

export default AiLanding;
