import React from 'react'
import styles from './Customers.module.scss'
import Image from 'next/image'
const Customers = () => {
  const customerList: Array<string> = [
    '/Amazon-icon.png',
    '/walmart-icon.png',
    '/target-logo.png',
    '/kroger-logo.png',
    '/albertsons-icon.png',
    '/99acres-logo.png',
    '/property-finder.png',
    '/dubizzle-logo.png',
    '/nykaa.webp',
    '/tira.webp',
    '/ajio-icon.jpeg',
    '/blinkit-icon.png',
    '/lazada-icon.png',
    '/myntra.jpeg',
    '/shopee-icon.png',
    '/Bayut-logo.png',
    '/facebook-logo.png',
    '/Instagram-logo.png',
  ]
  return (
    <div className={styles['customer-wrapper']}>
      <div className={styles['customer-title']}>
        <p>Dataset of 100+ Web Apps</p>
      </div>
      <div className={styles['customer-list']}>
        {customerList?.map((path: string, index: number) => (
          <Image
            key={index}
            height={50}
            width={50}
            alt={`feature-icon-${''}`}
            src={path}
            className={styles['customer-logo']}
          />
        ))}
      </div>
    </div>
  )
}

export default Customers
