import React from 'react'
import styles from './Innovator.module.scss'

const Innovator = () => {
  return (
    <div className={styles['container']}>
      <img
        src='product-img8.png'
        alt='background-img1'
        className={styles['dot-img']}
      />
      <img
        src='component-2.webp'
        alt='background-img2'
        className={styles['left-img']}
      />
      <img
        src='home-page5.webp'
        alt='background-img'
        className={styles['left-img-mobile']}
      />
      <div className={styles['header-container']}>
        <p className={styles['heading-text']}>
          Are you{' '}
          <span className={styles['bold-text']}>
            an innovator, an ideator or a disrupter?
          </span>
        </p>
        <p className={styles['supporting-text']}>
          We can aid you with our tech incubation solution and help you scale
          your business too!
        </p>
      </div>

      {/* ---------------------------------------------- */}
      <div className={styles['animation-container']}>
        <img src='home-page-animation.gif' />
        {/* <video loop muted autoPlay src='home-page-animation.webm' className={styles['video']}>
          </video> */}
        <div className={styles['animation-wrapper']}>
          <div className={styles['logo-div']}>
            <div className={styles['background-div-1']}>
              <img src='logo2.svg' alt='logo' className={styles['icon']} />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Start your journey</p>
              <p className={styles['supporting-text']}>with Socommerz</p>
            </div>
          </div>

          <div className={styles['ignite-div']}>
            <div className={styles['background-div-2']}>
              <img src='ignite.svg' alt='ignite' className={styles['icon']} />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Ignite</p>
              <p className={styles['supporting-text']}>
                Your Product development strategies.
              </p>
            </div>
          </div>

          <div className={styles['build-product-div']}>
            <div className={styles['background-div-3']}>
              <img
                src='build-product.svg'
                alt='build product'
                className={styles['icon']}
              />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Build product</p>
              <p className={styles['supporting-text']}>
                Build your product with the latest tech. stack.
                <br />
                things
              </p>
            </div>
          </div>

          <div className={styles['build-nurture-div']}>
            <div className={styles['background-div-4']}>
              <img
                src='build-and-nurture.svg'
                alt='build and nurture'
                className={styles['icon']}
              />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Team Augmentations</p>
              <p className={styles['supporting-text']}>
                With CXO&apos;s and Engineering
              </p>
            </div>
          </div>

          <div className={styles['direction-div']}>
            <div className={styles['background-div-5']}>
              <img
                src='direction.svg'
                alt='direction'
                className={styles['icon']}
              />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Direction</p>
              <p className={styles['supporting-text']}>
                Defining your growth strategies
              </p>
            </div>
          </div>

          <div className={styles['help-to-rise-div']}>
            <div className={styles['background-div-6']}>
              <img
                src='help-to-raise.svg'
                alt='help to raise'
                className={styles['icon']}
              />
            </div>
            <div className={styles['text']}>
              <p className={styles['heading']}>Help to Raise</p>
              <p className={styles['supporting-text']}>
                Connect with our HNI Network and Innovators.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Innovator
