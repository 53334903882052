import React, { useContext } from 'react';
import Button from '../../Common/Button/Button';
import styles from './Component7.module.scss';
import AppContext from '../../../context/AppContext';

const Component7 = () => {
  const { contactForm, setContactForm }: any = useContext(AppContext)

  return (
    <div className={styles['container']}>
      {/* <div>
        <div className={styles['header']}>
          <span className={styles['heading']}>
            Meet&nbsp;<span style={{ fontWeight: 600 }}>our investors</span>
          </span>
          <span className={styles['supporting-text']}>They have faith in us</span>
        </div>

        <div className={styles['images']}>
          <div className={styles['box-1']}>
            <img
              src="home-page2.webp"
              alt="background-img"
              style={{ width: 265, height: 222 }}
            />
            <div className={styles['rectangle']}>
              <img
                src="home-page4.png"
                alt="name"
                className={styles['name-img']}
                style={{ width: 126.54, height: 107.02 }}
              />
            </div>
            <p className={styles['text']}>India Accelerator</p>
          </div>

          <div className={styles['box-2']}>
            <img
              src="home-page3.webp"
              alt="background-img"
              className={styles['background-img']}
              style={{ width: 278, height: 260 }}
            />
            <div className={styles['rectangle']}>
              <img
                src="home-page4.png"
                alt="name"
                className={styles['name-img']}
                style={{ width: 126.54, height: 107.02 }}
              />
            </div>
            <p className={styles['text']}>Velocity Capital</p>
          </div>
        </div>
      </div> */}


      <div className={styles['text-button-div']}>
        <p className={styles['text']}>You&apos;ve got an awesome startup idea?</p>
        <p className={styles['text-mobile']}>
          Got an&nbsp;
          <span className={styles['bold-text']}>awesome startup idea?</span>
        </p>
        <div onClick={() => setContactForm(true)}>
          <Button text="Let's chat" classname="button-wrapper" />
        </div>
      </div>
    </div>
  );
};

export default Component7;
